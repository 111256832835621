import React from "react";
import axios from "axios";
import { Navbar } from "./home";
import { InventoryData } from "./inventory";
import { useState, useEffect } from "react";

export default function Cards() {
    const [invData, setInvData] = useState([])
    useEffect(() => {
        const fetchInvDate = async () => {
            axios({
                url: "https://yujinbot.xyz/api/getcards",
                method: "GET",
            })
            .then(res => {
                setInvData(res.data)
            })
            .catch(err => {
                console.log(err)
            })
        }
        fetchInvDate()
    }, [])

    return (
        <div>
            <Navbar/>
            <InventoryData 
                invData={invData} 
                setInvData={setInvData}
                title={"Card Database"}
            />
        </div>
    )
}